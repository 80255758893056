import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Row, Col } from "react-bootstrap"

class NotFoundPage extends React.Component {
  render() {
    return(
      <Layout pageInfo={{ pageName: "404" }}>
        <Seo title="404: Not found" />
        <Row id="overview" className="vh-100 align-items-center">
          <Col md={12} xs={12} sm={12}>
            <p className="textWhite textCenter">404</p>
          </Col>
        </Row>
      </Layout>
    )
  }
}

export default NotFoundPage
